.header {
    position: relative;
    text-align: center;
    color: white;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('./images/hero-image.png') no-repeat center center/cover; /* Use the hero image as a background */
    transition: height 0.8s ease, background 0.8s ease;
}

.header.scrolled {
    height: 80px;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    height: 70px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10; /* Higher z-index to ensure navbar is above the hero image */
}

.header .site-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 1em;
    transition: opacity 0.8s ease, transform 0.8s ease;
}

/* Additional Responsive Styling */
@media (max-width: 768px) {
    .header {
        height: 50vh;
    }

    .header .site-title {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .header {
        height: 40vh;
    }

    .header .site-title {
        font-size: 1.5rem;
    }
}
